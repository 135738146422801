<template>
    <ka-auth-page v-if="resetPasswordStatus.isSuccess">
        <div class="page-contant page-grid">
            <h1 class="tw-heading-1">{{ $t('global.titles.all_done') }}</h1>

            <p>
                {{ $t('auth.descriptions.check_email') }}
                <a href="mailto:kontakt@koderia.sk" class="kod-link">kontakt@koderia.sk</a>.
            </p>

            <router-link :to="{ name: 'auth-signin', params: { locale } }" class="kod-link-primary">
                {{ $t('auth.actions.sign_in_simple') }}
            </router-link>
        </div>
    </ka-auth-page>

    <ka-auth-page v-else>
        <form @submit.prevent="submit" class="page-contant page-grid">
            <h1 class="tw-heading-1">{{ $t('auth.actions.forgot_password') }}</h1>

            <div>
                <k-input v-model="email" type="email" title="E-mail" :emptyNotice="submitted" />
                <p class="mt-2">
                    {{ $t('auth.descriptions.email_associated_with_koderia') }}
                </p>
            </div>

            <v-expand-transition>
                <div v-show="resetPasswordStatus.error" class="form-error-message">
                    <p>{{ $t(resetPasswordStatus.error) }}</p>
                </div>

                <div v-show="errorMessage" class="form-error-message">
                    <p>{{ $t(errorMessage) }}</p>
                </div>
            </v-expand-transition>

            <div>
                <k-button
                    color="primary"
                    class="tw-w-full sm:tw-w-auto"
                    :loading="resetPasswordStatus.isLoading"
                    type="submit"
                >
                    {{ $t('global.actions.send') }}
                </k-button>
            </div>
        </form>
    </ka-auth-page>
</template>

<script>
import KaAuthPage from "./ka-auth-page";
import responsiveMixin from "./../responsiveMixin";
import { getRouteLocale } from '@web/constants/language'

export default {
    mixins: [responsiveMixin],
    components: {
        KaAuthPage
    },
    data() {
        return {
            email: null,
            submitted: false,
            errorMessage: null
        };
    },
    computed: {
        locale() {
            return getRouteLocale()
        },
        resetPasswordStatus() {
            return this.$store.getters["AUTH/getStatus"]('resetPasswordStatus')
        }
    },
    methods: {
        isValidField(fieldName) {
            switch (fieldName) {
                case "email":
                    return typeof this.email === "string" && /(.+)@(.+){2,}\.(.+){2,}/.test(this.email);
            }
        },

        isFormValid: function() {
            let valid = true;

            if (!this.isValidField("email")) {
                valid = false;

                this.errorMessage = 'global.form_rules.not_valid_email'
            } else if (this.errorMessage) {
                this.errorMessage = null
            }

            return valid;
        },
        submit() {
            this.submitted = true;

            if (!this.isFormValid()) {
                return;
            }
            this.$store.dispatch('AUTH/resetPassword', {
                email: this.email
            });
        }
    }
};
</script>

<style lang="scss" scoped>
@import "@web/sass/variables.scss";

.page-contant {
    width: 25rem;
}

.page-grid {
    display: grid;
    gap: 2rem;
}

.form-error-message p {
    margin-top: 0 !important;
}
</style>
